<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-resv-menu></admin-resv-menu>
                        </v-col>
                        <v-col cols="12" md="10" class="">
                            <v-row>
                                <v-col class="overflow-x-auto" v-if="!isMobile">
                                    <table class="table-left-s table-left-s-b" style="min-width: 800px;">
                                        <colgroup>
                                            <col style="width:25%"/>
                                            <col style="width:75%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">출력갯수</th>
                                            <td class="td">
                                                <select v-model="page.limit">
                                                    <!--<option v-for="(n, i) in 5" :value="n">{{n}}</option>-->
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th rowspan="2">검색기간</th>
                                            <td>
                                                <v-btn
                                                        class="mx-1 white--text center-block tmp-btn btn-gray"
                                                        x-small
                                                        @click="setSearchBtn(1)"
                                                >
                                                    1개월
                                                </v-btn>
                                                <v-btn
                                                        class="mx-1 white--text center-block tmp-btn btn-gray"
                                                        x-small
                                                        @click="setSearchBtn(2)"
                                                >
                                                    2개월
                                                </v-btn>
                                                <v-btn
                                                        class="mx-1 white--text center-block tmp-btn btn-gray"
                                                        x-small
                                                        @click="setSearchBtn(3)"
                                                >
                                                    3개월
                                                </v-btn>
                                                <v-btn
                                                        class="mx-1 white--text center-block tmp-btn btn-gray"
                                                        x-small
                                                        @click="setSearchBtn(6)"
                                                >
                                                    6개월
                                                </v-btn>
                                                <v-btn
                                                        class="mx-1 white--text center-block tmp-btn btn-gray"
                                                        x-small
                                                        @click="setSearchBtn(12)"
                                                >
                                                    12개월
                                                </v-btn>
                                                <v-btn
                                                        class="mx-1 white--text center-block tmp-btn btn-gray"
                                                        x-small
                                                        @click="setSearchBtn(0)"
                                                >
                                                    초기화
                                                </v-btn>
                                            </td>
                                        </tr>
                                        <tr>
                                            <!--<th class="th">검색기간</th>-->
                                            <td class="td">
                                                <div style="float:left">
                                                    <input id="startDate" style="width: 120px;" v-model="searchDate.startDate" readonly>~
                                                    <input id="endDate" style="width: 120px;" v-model="searchDate.endDate" readonly>
                                                </div>

                                                <div style="float:left; padding:5px;">
                                                    <input type="radio" id="Y" value="N" v-model="searchDate.isRegDate"
                                                           style="width: 20px; height: 12px;">
                                                    <label for="Y">예약일</label>
                                                    <input type="radio" id="N" value="Y" v-model="searchDate.isRegDate"
                                                           style="width: 20px; height: 12px;">
                                                    <label for="N">신청일</label>
                                                </div>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">검 색 어</th>
                                            <td class="td">
                                                <select v-model="search.searchGubun" class="float-left my-1" style="width: 110px;">
                                                    <!--<option v-for="(n, i) in 5" :value="n">{{n}}</option>-->
                                                    <option value="0">------------</option>
                                                    <option value="1">예약ID</option>
                                                    <!--<option value="2">예약자</option>-->
                                                    <!--<option value="3">입금자</option>-->
                                                    <option value="6">고객명</option>
                                                    <option value="4">객실명</option>
                                                    <option value="5">전화번호</option>
                                                </select>
                                                <input v-model="search.searchText" placeholder="검색어를 입력해주세요."
                                                       v-on:keyup.enter="searchClick" style="width: 55%;">
                                                <v-btn
                                                        class="mx-3 white--text center-block tmp-btn btn-gray"
                                                        x-small
                                                        @click="searchClick"
                                                >
                                                    검색
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                                <v-col v-else cols="12" md="6" class="table-left-s" style="border:0px;">
                                    <div class="search-form">
                                        <select v-model="search.searchGubun" class="float-left my-1 select-s">
                                            <option value="0">------------</option>
                                            <option value="1">예약ID</option>
                                            <option value="6">고객명</option>
                                            <option value="4">객실명</option>
                                            <option value="5">전화번호</option>
                                        </select>
                                        <input v-model="search.searchText" placeholder="검색어를 입력해주세요."
                                               v-on:keyup.enter="searchClick" >
                                        <v-btn
                                                color="primary"
                                                class="mx-1 my-1 white--text center-block tmp-btn"
                                                small
                                                @click="searchClick"
                                        >
                                            검색
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="sub-title-3 mt-2">
                                    예약관리 ({{resvGubunDesc}})
                                </v-col>
                                <v-col cols="12" class="sub-title-3 mt-2" v-if="isMobile">
                                    <div  style="float: left;">
                                        <v-btn
                                                color="primary"
                                                class="white--text center-block tmp-btn float-right"
                                                style="font-weight: bold; "
                                                small
                                                @click="excelClick"
                                        >
                                            엑셀출력
                                        </v-btn>
                                        <v-btn
                                                class="mx-3 white--text center-block tmp-btn float-right"
                                                style="font-weight: bold; background-color: #E53935;margin-left:0 !important;"
                                                small
                                                @click="newResvClick"
                                        >
                                            실시간예약
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <div style="width: 100%">
                                        <div v-if="isSelect && !isMobile" style="display: flex;float: left;">
                                            <span style="margin-right:5px;">선택항목 </span>
                                            <!--<select v-model="exResvGubun" class="select-s"
                                                    @change="selectChange($event)">
                                                &lt;!&ndash;<option v-for="(n, i) in 5" :value="n">{{n}}</option>&ndash;&gt;
                                                <option value="1">예약대기</option>
                                                <option value="2">입금완료</option>
                                                <option value="3">환불신청</option>
                                                <option value="4">환불완료</option>
                                                <option value="5">예약취소</option>
                                            </select>-->
                                            <select  v-model="exResvGubun" class="select-s"
                                                     @change="selectChange($event)">
                                                <option v-for="item in selectList" :value="item.resvGubun">{{item.resvGubunDesc}}</option>
                                            </select>
                                            <span style="margin-left:5px;">변경</span>
                                            <v-btn
                                                    color="primary"
                                                    class="mx-3 white--text center-block tmp-btn"
                                                    small
                                                    @click="checkBtnClick"
                                            >
                                                적용

                                            </v-btn>
                                        </div>
                                        <div v-if="!isMobile" style="float: right;">
                                            <v-btn
                                                    color="primary"
                                                    class="mx-3 white--text center-block tmp-btn float-right"
                                                    style="font-weight: bold"
                                                    small
                                                    @click="excelClick"
                                            >
                                                엑셀출력
                                            </v-btn>
                                            <v-btn
                                                    class="mx-3 white--text center-block tmp-btn float-right"
                                                    style="font-weight: bold; background-color: #E53935;"
                                                    small
                                                    @click="newResvClick"
                                            >
                                                실시간예약
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-col>

                                <!--<v-col class="hidden-md-and-down">-->
                                <v-col cols="12" class="overflow-x-auto">
                                    <table class="adminListTable tableHover" style="">
                                        <colgroup>
                                            <col style="width:4%"/>
                                            <col style="width:7%"/>
                                            <col style="width:9%"/>
                                            <col style="width:11%"/>

                                            <!--<col style="width:7%"/>-->
                                            <col style="width:8%"/>
                                            <col style="width:7%"/>

                                            <col style="width:12%"/>
                                            <col style="width:12%"/>

                                            <col style="width:10%"/>
                                            <col style="width:11%"/>
                                            <col style="width:8%"/>

                                            <!--<col style="width:5%"/>-->
                                        </colgroup>
                                        <tr>
                                            <th></th>
                                            <th>예약ID</th>
                                            <th>객실명</th>
                                            <th>예약일</th>
                                            <th>기간</th>
                                            <th>인원</th>
                                            <th>예약자</th>
                                            <th>입금자</th>
                                            <!--<th>전화번호</th>-->
                                            <th>이용금액</th>
                                            <th>신청일</th>
                                            <th>예약상태</th>
                                            <!--<th>수정</th>-->
                                        </tr>
                                        <tbody>
                                        <tr
                                                v-for="(item,i) in resvList"
                                                :key="i">
                                            <td>

                                                <input type="checkbox" v-model="item.isCheck" class="checkbox-s"
                                                       v-if="item.resvGubun != '4' && item.resvGubun != '5'">
                                            </td>
                                            <td @click="rowClick(item)" style="cursor: pointer">{{item.resvId}}</td>
                                            <td @click="rowClick(item)" style="cursor: pointer">{{item.resvRoomNm}}</td>
                                            <td @click="rowClick(item)" style="cursor: pointer">{{item.resvDate}}</td>
                                            <td @click="rowClick(item)" style="cursor: pointer">{{item.resvDateRange}}</td>
                                            <td @click="rowClick(item)" style="cursor: pointer">{{item.resvNop}}명</td>
                                            <td @click="rowClick(item)" style="cursor: pointer">{{item.resvName}}</td>
                                            <td @click="rowClick(item)" style="cursor: pointer">{{item.payName}}</td>
                                            <!--<td>{{item.resvPhone}}</td>-->
                                            <td @click="rowClick(item)" style="cursor: pointer" class="text-right">{{item.resvPrice | comma}}원</td>
                                            <td @click="rowClick(item)" style="cursor: pointer">{{item.lstModDate}}</td>
                                            <td @click="rowClick(item)" style="cursor: pointer">
                                                <template v-if="item.resvGubun === '1'">
                                                    <span style="color:blue;">{{item.resvGubunDesc}}</span>
                                                </template>
                                                <template v-else-if="item.resvGubun === '2'">
                                                    <span style="color:black;">{{item.resvGubunDesc}}</span>
                                                </template>
                                                <template v-else-if="item.resvGubun === '5'">
                                                    <span style="color:green;">{{item.resvGubunDesc}}</span>
                                                </template>
                                                <template v-else>
                                                    <span style="color:red;">{{item.resvGubunDesc}}</span>
                                                </template>

                                            </td>
                                        </tr>
                                        </tbody>

                                    </table>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col>
                                    <div class="text-center pt-2">
                                        <v-pagination
                                                v-model="page.page"
                                                :length="page.length"
                                                @input="next"
                                        ></v-pagination>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AdminResvMenu from '../../components/AdminResvMenu'
    export default {
        name: 'AdminResvList',
        components: {
            AdminResvMenu
        },
        data: () => ({
            resvList: [],
            resvInfo: {},
            resvGubun: "",
            resvGubunDesc: "",
            exResvGubun: "1",
            isSelect: true,
            selectList: [
                {resvGubun:"1", resvGubunDesc: "예약대기"},
                {resvGubun:"2", resvGubunDesc: "입금완료"},
                {resvGubun:"3", resvGubunDesc: "환불신청"},
                {resvGubun:"4", resvGubunDesc: "환불완료"},
                {resvGubun:"5", resvGubunDesc: "대기취소"},
            ],
            menuCnt: {
                total: 0,
                menu1: 0,
                menu2: 0,
                menu3: 0,
                menu4: 0,
                menu5: 0,
            },
            page: {
                limit: 50,  // 한페이지 표시 갯수
                offset: 0,  // %부터 보여주기
                total: '0', // 전체 갯수
                page: 1,    // 현제 페이지
                length: 1   // 페이지 표시 갯수
            },
            search: {
                isSearch: false,
                searchGubun: "6",
                searchText: "",

            },
            searchDate: {
                startDate: "",
                endDate: "",
                isRegDate: "N",
            },
            isMobile: false,

        }),
        created: function () {

            // 모바일 체크
            this.isMobile = this.$isMobile();

            // 맨위로
            window.scrollTo(0, 0);

            let resvGubun = this.$getParameterByName('resvGubun');
            this.resvGubun = resvGubun;
            this.resvGubunDesc  = "";{
                switch (this.resvGubun) {
                    case "1": {
                        this.resvGubunDesc = "예약대기";
                        break;
                    }
                    case "2": {
                        this.resvGubunDesc = "입금완료";
                        break;
                    }
                    case "3": {
                        this.resvGubunDesc = "환불신청";
                        break;
                    }
                    case "4": {
                        this.resvGubunDesc = "환불완료";
                        break;
                    }
                    case "5": {
                        this.resvGubunDesc = "대기취소";
                        break;
                    }
                    default :{
                        this.resvGubunDesc = "예약전체";
                        break;
                    }
                }
            }

            this.getList();

            setTimeout(() => {

                this.setDatePicker();

            }, 300)

        },
        methods: {
            getList(val) {

                let formdata = {};

                if (val !== undefined) {

                    formdata = val;
                } else {
                    formdata = {}
                }

                this.exResvGubun = this.resvGubun;
                this.isSelect = false;
                switch (this.resvGubun) {
                    case '1' :{
                        this.selectList = [
                            {resvGubun:"1", resvGubunDesc: "예약대기"},
                            {resvGubun:"2", resvGubunDesc: "입금완료"},
                            {resvGubun:"5", resvGubunDesc: "대기취소"},
                        ];


                        formdata['resvGubun'] = this.resvGubun;
                        formdata['delYn'] = "N";

                        this.isSelect = true;

                        break;
                    }

                    case '2' :{
                        this.selectList = [
                            {resvGubun:"2", resvGubunDesc: "입금완료"},
                            {resvGubun:"3", resvGubunDesc: "환불신청"},
                        ];

                        formdata['resvGubun'] = this.resvGubun;
                        formdata['delYn'] = "N";
                        this.isSelect = true;

                        break;
                    }
                    case '3' : {
                        this.selectList = [
                            {resvGubun:"3", resvGubunDesc: "환불신청"},
                            {resvGubun:"4", resvGubunDesc: "환불완료"},
                        ];

                        this.isSelect = true;

                        formdata['resvGubun'] = this.resvGubun;
                        formdata['delYn'] = "N";

                        break;
                    }
                    case '4' :{
                        this.selectList = [
                            {resvGubun:"4", resvGubunDesc: "환불완료"},
                        ];

                        this.isSelect = false;

                        formdata['resvGubun'] = this.resvGubun;
                        formdata['delYn'] = "N";

                        break;
                    }
                    case '5' : {
                        this.selectList = [
                            {resvGubun:"5", resvGubunDesc: "대기취소"},
                        ];

                        this.isSelect = false;
                        formdata['resvGubun'] = this.resvGubun;
                        formdata['delYn'] = "N";

                        break;
                    }
                    default: {

                        this.selectList = [
                            {resvGubun:"1", resvGubunDesc: "예약대기"},
                            {resvGubun:"2", resvGubunDesc: "입금완료"},
                            {resvGubun:"3", resvGubunDesc: "환불신청"},
                            {resvGubun:"4", resvGubunDesc: "환불완료"},
                            {resvGubun:"5", resvGubunDesc: "대기취소"},
                        ];

                        formdata['delYn'] = "N";
                        break;
                    }
                }

                formdata['limit'] = this.page.limit;
                formdata['offset'] = this.page.offset;

                return this.$store.dispatch("admin/getResvList", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.resvList = resp.message;
                            this.page.total = resp.total;
                            this.setPage();

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            getCnt() {
                return this.$store.dispatch("admin/getResvCnt")
                    .then((resp) => {
                        this.menuCnt = resp.menuCnt;
                    })
                    .catch((err) => {
                    })
            },
            setPage() {
                /*page: {
                    limit: 30,  // 한페이지 표시 갯수
                        offset: 0,  // %부터 보여주기
                        total: '0', // 전체 갯수
                        page: 1,    // 현제 페이지
                        length: 1   // 페이지 표시 갯수
                }*/


                this.page.length = Math.ceil(this.page.total / this.page.limit);
                if (this.page.length === 0) this.page.length = 1;

            },
            next(page) {
                this.page.page = page;
                this.page.offset = this.page.limit * (this.page.page - 1);
                //this.getList();
                this.searchClick();
            },
            rowClick(row) {

                let options = 'top=10, left=10, width=700, height=800, status=no, menubar=no, toolbar=no, resizable=no';
                let route = '/popup/resv/info?resvId=' + row.resvId;
                let popNm = "상세정보" + row.resvId;
                window.open(route, popNm, options);

            },

            menuClick(route, val) {
                location.href = route + '?resvGubun=' + val;
            },
            selectChange(event) {

                this.resvList.forEach(function (e) {
                    if (e.isCheck) {
                    }

                });

            },
            checkBtnClick() {

                let exList = [];
                let _this = this;

                this.resvList.forEach(function (e) {
                    if (e.isCheck) {
                        e.resvGubun = _this.exResvGubun;
                        exList.push(e);
                    }

                });

                if (exList.length === 0) {
                    alert("항목을 선택해 주세요.")
                    return;
                }

                let formdata = {
                    resvList: exList,
                    exResvGubun: _this.exResvGubun
                }

                return this.$store.dispatch("admin/updateResvs", formdata)
                    .then((resp) => {
                        setTimeout(() => {
                            alert("정보를 저장 했습니다.");
                            //this.getList();
                            this.$router.go();

                        }, 300)
                    })
                    .catch((err) => {
                    })


            },
            newResvClick() {
                let routeData = this.$router.resolve({path: '/reservation/search'});
                window.open(routeData.href, '_blank');
            },
            excelClick() {

                let param = "?";

                if(this.resvGubun !== 'all'){
                    param = param + "resvGubun=" + this.resvGubun + "&";
                }

                if(this.search.isSearch){
                    // 검색어추가
                    switch (this.search.searchGubun) {
                        case "1" : {
                            param = param + "resvIdLike=" + this.search.searchText + "&";
                            break;
                        }
                        case "2" : {
                            param = param + "resvNmLike=" + this.search.searchText + "&";
                            break;
                        }
                        case "3" : {
                            param = param + "payNmLike=" + this.search.searchText + "&";
                            break;
                        }
                        case "4" : {
                            param = param + "resvRoomNmLike=" + this.search.searchText + "&";
                            break;
                        }
                        case "5" : {
                            param = param + "resvPhoneLike=" + this.search.searchText + "&";
                            break;
                        }
                        case "6" : {
                            param = param + "nameLike=" + this.search.searchText + "&";
                            break;
                        }
                    }

                    // 기간추가
                    if(!this.isMobile){
                        let startDate = $('#startDate').val();
                        let endDate = $('#endDate').val();

                        if (startDate.length > 0) {
                            if (this.searchDate.isRegDate === 'N') {
                                param = param + 'resvStartDate=' + startDate + "&";
                            } else {
                                param = param + 'startDate=' + startDate + "&";
                            }
                        }

                        if (endDate.length > 0) {
                            if (this.searchDate.isRegDate === 'N') {
                                param = param + 'resvEndDate=' + endDate + "&";
                            } else {
                                param = param + 'endDate=' + endDate + "&";
                            }
                        }
                    }
                }

                let BASE_URL = void 0;

                let host = void 0;
                let port = void 0;

                switch (document.location.hostname) {
                    case "localhost":
                    case "127.0.0.1": {
                        host = "192.168.0.100";
                        port = "8082";
                        break;
                    }
                    case "211.238.111.60": {
                        host = "211.238.111.60";
                        port = "58082";
                        break;
                    }
                    case "192.168.0.100":{
                        host = "192.168.0.100";
                        port = "8082";
                        break;
                    }
                    case "valley.anystar4u.com": {
                        host = "valley.anystar4u.com";
                        port = null;
                        break;
                    }
                    default: {
                        host = document.location.hostname;
                        port = null;
                        break;
                    }
                }

                let protocol = document.location.protocol;

                BASE_URL = protocol+"//" + host + (port == null? "" : (":" + port));

                //location.href = "http://192.168.0.100:8082/api/admin1/excel/resv/getList.do"
                //location.href = BASE_URL + "/api/admin1/excel/resv/getList.do"

                location.href = BASE_URL + "/api/admin1/excel/resv/getList.do" + param;
            },
            setSearchBtn(val){

                if(val === 0 ){

                    this.searchDate.endDate =  "";
                    this.searchDate.startDate = "";

                }else {
                    let date = new Date();
                    let year = date.getFullYear();
                    let month = ("0" + (1 + date.getMonth())).slice(-2);
                    let day = ("0" + date.getDate()).slice(-2);

                    this.searchDate.endDate =  year + "-" + month + "-" + day;

                    // val(달) 전
                    let monthAgo = new Date(date.setMonth(date.getMonth() - val));

                    let year1 = monthAgo.getFullYear();
                    let month1 = ("0" + (1 + monthAgo.getMonth())).slice(-2);
                    let day1 = ("0" + monthAgo.getDate()).slice(-2);

                    this.searchDate.startDate =  year1 + "-" + month1 + "-" + day1;
                }

            },
            searchClick() {

                let formdata = {};

                switch (this.search.searchGubun) {
                    case "1" : {
                        formdata["resvIdLike"] = this.search.searchText;
                        break;
                    }
                    case "2" : {
                        formdata["resvNmLike"] = this.search.searchText;
                        break;
                    }
                    case "3" : {
                        formdata["payNmLike"] = this.search.searchText;
                        break;
                    }
                    case "4" : {
                        formdata["resvRoomNmLike"] = this.search.searchText;
                        break;
                    }
                    case "5" : {
                        formdata["resvPhoneLike"] = this.search.searchText;
                        break;
                    }
                    case "6" : {
                        formdata["nameLike"] = this.search.searchText;
                        break;
                    }

                }

                if(!this.isMobile){
                    let startDate = $('#startDate').val();
                    let endDate = $('#endDate').val();

                    if (startDate.length > 0) {
                        if (this.searchDate.isRegDate === 'N') {
                            formdata['resvStartDate'] = startDate;
                        } else {
                            formdata['startDate'] = startDate;
                        }

                    }

                    if (endDate.length > 0) {
                        if (this.searchDate.isRegDate === 'N') {
                            formdata['resvEndDate'] = endDate;
                        } else {
                            formdata['endDate'] = endDate;
                        }

                    }
                }

                this.search.isSearch = true;

                this.getList(formdata);

            },
            setDatePicker() {
                let _this = this;
                this.$nextTick(function () {
                    let todayYear = this.$getTodayYear()+3;
                    $('#startDate').daterangepicker({
                        "locale": {
                            "format": "YYYY-MM-DD",
                            "separator": " ~ ",
                            "applyLabel": "확인",
                            "cancelLabel": "취소",
                            "daysOfWeek": ["일", "월", "화", "수", "목", "금", "토"],
                            "monthNames": ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
                        }, singleDatePicker: true,
                        showDropdowns: true,
                        minYear:2012,
                        maxYear:todayYear,
                    }).on('cancel.daterangepicker', function(ev, picker) {
                        $(ev.currentTarget).val('');
                    }).on('apply.daterangepicker', function(ev, picker) {
                        _this.searchDate.startDate = picker.startDate.format('YYYY-MM-DD')
                    });

                    $('#endDate').daterangepicker({
                        "locale": {
                            "format": "YYYY-MM-DD",
                            "separator": " ~ ",
                            "applyLabel": "확인",
                            "cancelLabel": "취소",
                            "daysOfWeek": ["일", "월", "화", "수", "목", "금", "토"],
                            "monthNames": ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
                        }, singleDatePicker: true,
                        showDropdowns: true,
                        minYear:2012,
                        maxYear:todayYear,
                    }).on('cancel.daterangepicker', function(ev, picker) {
                        $(ev.currentTarget).val('');
                    }).on('apply.daterangepicker', function(ev, picker) {
                        _this.searchDate.endDate = picker.startDate.format('YYYY-MM-DD')
                    });

                    $('#startDate').val('');
                    $('#endDate').val('');


                })
            }

        },

    }
</script>

<style>

</style>

