<template>
    <div>
        <div class="sideMenu-title mb-2">
            예약관리
        </div>
        <div class="sideMenu">
            <!--<ul style="width:100%;" class="default-Btn-1 my-12 hidden-sm-and-down">-->
            <div class="sideMenu-group">
                <ul style="width:100%;" class="">
                    <!--<li class="active">
                        <a @click="menuClick('/admin/resvList')">객실목록</a>
                    </li>-->
                    <li class="" id="menu-0">
                        <a :class="{active : menuActive.isActive0}" @click="menuClick('/admin/resv/list','all')">예약전체 [{{menuCnt.total}}]</a>
                    </li>
                    <li class="" id="menu-1">
                        <a :class="{active : menuActive.isActive1}" @click="menuClick('/admin/resv/list','1')">예약대기 [{{menuCnt.menu1}}]</a>
                    </li>
                    <li class="" id="menu-2">
                        <a :class="{active : menuActive.isActive2}" @click="menuClick('/admin/resv/list','2')">입금완료 [{{menuCnt.menu2}}]</a>
                    </li>
                    <li class="" id="menu-3">
                        <a :class="{active : menuActive.isActive3}" @click="menuClick('/admin/resv/list','3')">환불신청 [{{menuCnt.menu3}}]</a>
                    </li>
                    <li class="" id="menu-4">
                        <a :class="{active : menuActive.isActive4}" @click="menuClick('/admin/resv/list','4')">환불완료 [{{menuCnt.menu4}}]</a>
                    </li>
                    <li class="" id="menu-5">
                        <a :class="{active : menuActive.isActive5}" @click="menuClick('/admin/resv/list','5')">대기취소 [{{menuCnt.menu5}}]</a>
                    </li>
                </ul>
            </div>
            <v-divider class="mx-5 my-1"></v-divider>
            <div class="sideMenu-group">
                <ul style="width:100%;" class="">
                    <li class="" id="menu-6">
                        <a :class="{active : menuActive.isActive6}" @click="menuClick('/admin/resv/calendar', null)">월별예약현황</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'AdminResvMenu',
        data: () => ({
            menuCnt: {
                total: 0,
                menu1: 0,
                menu2: 0,
                menu3: 0,
                menu4: 0,
                menu5: 0,
            },
            menuActive: {
                isActive0: false,
                isActive1: false,
                isActive2: false,
                isActive3: false,
                isActive4: false,
                isActive5: false,
                isActive6: false,
            }


        }),
        created: function () {
            this.getCnt();
            this.setMenuActive();

        },
        computed: {

        },
        methods: {
            getCnt() {
                return this.$store.dispatch("admin/getResvCnt")
                    .then((resp) => {
                        this.menuCnt = resp.menuCnt;
                    })
                    .catch((err) => {
                    })
            },
            menuClick(route, gubun) {

                if(gubun != null){

                    location.href = route + '?resvGubun=' + gubun;
                }else{
                    location.href = route;
                }
            },
            setMenuActive(){
                switch (window.location.pathname) {
                    case "/admin/resv/calendar":{
                        this.menuActive.isActive6 = true;
                        break;
                    }
                    case "/admin/resv/list":{
                        let resvGubun = this.$getParameterByName('resvGubun');
                            switch (resvGubun) {
                                case "1": {
                                    this.menuActive.isActive1 = true;
                                    break;
                                }
                                case "2": {
                                    this.menuActive.isActive2 = true;
                                    break;
                                }
                                case "3": {
                                    this.menuActive.isActive3 = true;
                                    break;
                                }
                                case "4": {
                                    this.menuActive.isActive4 = true;
                                    break;
                                }
                                case "5": {
                                    this.menuActive.isActive5 = true;
                                    break;
                                }
                                default :{
                                    this.menuActive.isActive0 = true;
                                    break;
                                }
                            }
                        break;
                    }
                }

            }

        },

    }

</script>

<style lang="scss" scoped>
    /*@import './base.scss';*/


</style>
